<template>
	<v-overlay :visible="visible" @close="close">
		<div class="flex space-between align-center mb-30">
			<div>
				<h1 class="flex align-center">
					<i
						class="fas fa-circle fa-xs mr-10"
						:class="{
							'color-green': agreement.agreementLink && agreement.agreementLink.active,
							'color-red': agreement.agreementLink && !agreement.agreementLink.active,
						}"
					></i>
					Einnýtisleinkja
				</h1>
			</div>

			<div
				class="overlay-close"
				@click.prevent="
					$v.$reset();
					close();
				"
			></div>
		</div>

		<div class="mb-30">
			<label class="mb-10">Deil leinkju</label>

			<div class="grid grid-3-1 grid-gap-20">
				<input ref="link" v-model="link" type="text" disabled />

				<div class="btn" @click="copyLink">Kopiera</div>
			</div>
		</div>

		<div class="mb-30">
			<label class="mb-10">Send via teldupost</label>

			<div class="grid grid-3-1 grid-gap-20">
				<input v-model="email" type="email" />

				<div class="btn" :class="{ disabled: !email || $v.email.$invalid }" @click="sendEmail">Send</div>
			</div>
		</div>

		<hr />

		<p class="mb-30 mt-30 color-grey">
			Vitjað <strong>{{ numberOfVisits }}</strong> ferðir
		</p>

		<div class="flex align-center">
			<v-date-picker v-model="date" mode="dateTime" is24hr is-range :minute-increment="5">
				<template v-slot="{ inputValue, inputEvents }">
					<div class="grid grid-2 grid-gap-20 mb-30" v-on="inputEvents.start">
						<div>
							<label class="mb-10">Galdandi frá</label>

							<input type="text" v-model="inputValue.start" />
						</div>

						<div>
							<label class="mb-10">Galdandi til</label>

							<input type="text" v-model="inputValue.end" />
						</div>
					</div>
				</template>
			</v-date-picker>
		</div>

		<div v-if="!agreement.agreementLink" class="flex space-between">
			<div class="btn inline-block background-green" :class="{ disabled: loading.deactivate }" @click="createAgreementLink">
				{{ loading.deactivate ? 'Bíða við...' : 'Stovna leinkju' }}
			</div>
		</div>

		<div v-if="agreement.agreementLink" class="flex space-between">
			<div
				v-if="!agreement.agreementLink.active"
				class="btn inline-block background-green"
				:class="{ disabled: loading.deactivate }"
				@click="toggleActive"
			>
				{{ loading.deactivate ? 'Bíða við...' : 'Virkja leinkju' }}
			</div>

			<div v-if="agreement.agreementLink.active" class="btn inline-block background-red" :class="{ disabled: loading.deactivate }" @click="toggleActive">
				{{ loading.deactivate ? 'Bíða við...' : 'Óvirkja leinkju' }}
			</div>

			<div class="btn inline-block" :class="{ disabled: loading.save }" @click="updateLink">
				{{ loading.save ? 'Bíða við...' : 'Dagfør' }}
			</div>
		</div>
	</v-overlay>
</template>

<script>
import { email } from 'vuelidate/lib/validators';
import VOverlay from '@/components/VOverlay.vue';

import axios from 'axios';

export default {
	name: 'LinkOverlay',

	props: {
		visible: Boolean,
		agreement: Object,
	},

	components: {
		VOverlay,
	},

	data() {
		return {
			email: '',
			date: {
				start: null,
				end: null,
			},

			loading: {
				deactivate: false,
				save: false,
			},
		};
	},

	computed: {
		link() {
			if (!this.agreement) {
				return '';
			}

			return `${process.env.VUE_APP_TERMINAL_CLIENT_URL}/client?token=${this.agreement?.agreementLink?.token}`;
		},

		numberOfVisits() {
			return this.agreement?.agreementLink?.numberOfVisits || 0;
		},
	},

	watch: {
		agreement: function (value) {
			if (value.customer.email) {
				this.email = value.customer.email;
			}

			this.date.start = value.agreementLink.from;
			this.date.end = value.agreementLink.to;
		},
	},

	methods: {
		copyLink() {
			this.$refs.link.disabled = false;
			this.$refs.link.select();
			document.execCommand('copy');
			this.$refs.link.disabled = true;
		},

		sendEmail() {
			axios
				.post(`/agreement-links/${this.agreement?.agreementLink?.id}/send`, {
					email: this.email,
				})
				.then(() => {
					this.$emit('close');
				})
				.catch((error) => {
					console.error(error);
				});
		},

		async createAgreementLink() {
			this.loading.deactivate = true;

			await axios
				.post(`/agreement-links`, {
					agreement: {
						id: this.agreement.id,
					},
				})
				.then((response) => {
					this.$emit('created', response.data);

					this.agreement.agreementLink = response.data;
				})
				.catch((error) => {
					console.error(error);
				});

			this.loading.deactivate = false;
		},

		async toggleActive() {
			this.loading.deactivate = true;

			await axios
				.post(`/agreement-links/${this.agreement?.agreementLink?.id}/toggle-active`)
				.then((response) => {
					// this.$emit('deleted', response.data);
					this.date = null;

					this.agreement.agreementLink = response.data;
				})
				.catch((error) => {
					console.error(error);
				});

			this.loading.deactivate = false;
		},

		async updateLink() {
			this.loading.save = true;

			await axios
				.put(`/agreement-links/${this.agreement?.agreementLink?.id}`, {
					from: this.date ? this.date.start : null,
					to: this.date ? this.date.end : null,
				})
				.then((response) => {
					this.$emit('updated', response.data);

					this.$emit('close');

					this.agreement.agreementLink = response.data;
				})
				.catch((error) => {
					console.error(error);
				});

			this.loading.save = false;
		},

		close() {
			this.$emit('close');
			// this.email = '';
			// this.from = null;
			// this.to = null;A
		},
	},

	validations: {
		email: {
			email,
		},
	},
};
</script>

<style lang="scss" scoped>
.flex span {
	width: 100%;
}

.btn {
	text-align: center;
}
</style>
